import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import { Timekeeper } from '../types'
import s from './ActionsPopover.scss'

interface Props {
  timekeeper: Timekeeper
  copyTk: (row: Timekeeper) => void
  downloadTk: (row: Timekeeper) => void
}

const ActionsPopover = ({ timekeeper, copyTk, downloadTk }: Props) => {
  const actions = [
    {
      name: 'VIEW',
      text: 'View Details',
      onClick() {
        return
      }
    },
    {
      name: 'DOWNLOAD',
      text: 'Download',
      onClick() {
        return
      }
    }
  ]

  return (
    <Popover
      testId={`item-${timekeeper.timekeeper_file_id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
