import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import s from './EnhancedTimekeeepersContainer.scss'
import cn from 'classnames'
import { FaMoneyBill } from 'react-icons/fa'
import { AvatarList, Button, ChatHover, DataTableWrapper, useLoading } from 'simple-core-ui'
import { Tabs } from './Tabs'
import { Cell, Params, Timekeeper } from './types'
import ThemeContext from 'context/ThemeContext'
import { makeGetRequest } from 'simple-core-ui/utils/api'
import { ActionsPopover } from './ActionsPopover'
import { Updater, useImmer } from 'use-immer'
import { formatDate } from './helpers'
import { serializeTimekeepers } from './serializers'
import { GoCheckCircleFill, GoXCircle } from 'react-icons/go'
import { useDispatch } from 'react-redux'

const EnhancedTimekeepersContainer = () => {
  const [localState, setLocalState] = useState({
    params: {
      pageSize: 50,
      ordering: { columnKey: 'created_date', isDesc: false },
      search: '',
      page: 1,
      category: 'pending'
    }
  })
  const { params } = localState

  const [view, setView] = useState('rates')
  const dispatch = useDispatch()

  const { state } = useContext(ThemeContext)
  const [selectedTab, setSelectedTab] = useState(params.category)
  const [oldSelectedTab, setOldSelectedTab] = useState(params.category)
  const [timekeepers, setTimekeepers]: [Timekeeper[], Updater<Timekeeper[]>] = useImmer<
    Timekeeper[]
  >([])
  const [totalEntries, setTotalEntries] = useState(0)
  const [pendingCount, setPendingCount] = useState(0)
  const [completedCount, setCompletedCount] = useState(0)
  const [allCount, setAllCount] = useState(0)

  const [isLoading, withLoadingLocksGetTks] = useLoading()

  const fetchCounts = async () => {
    try {
      const { completedBatchCount, pendingBatchCount, totalRates } = await makeGetRequest(
        '/timekeepers/tk_rates_count/'
      )

      setCompletedCount(completedBatchCount)
      setPendingCount(pendingBatchCount)
      setAllCount(totalRates)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const fetchTimekeepers = async (selectedTab: string, tableParams: Params) => {
    try {
      const url = ['pending', 'completed'].includes(selectedTab)
        ? '/timekeepers/timekeeper_batch_list/'
        : '/timekeepers/timekeeper_rates_list/'
      const urlTableParams = {
        columnKey: tableParams.ordering.columnKey,
        search: tableParams.search,
        page_number: tableParams.page,
        page_size: tableParams.pageSize,
        ...(tableParams.ordering.isDesc ? { isDesc: tableParams.ordering.isDesc } : {})
      }
      const urlParams =
        selectedTab === 'completed'
          ? { params: { batch: selectedTab, ...urlTableParams } }
          : { params: urlTableParams }

      const { rows, totalEntries } = await withLoadingLocksGetTks(makeGetRequest(url, urlParams))

      setTimekeepers(serializeTimekeepers(rows))
      setTotalEntries(totalEntries)
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  useEffect(() => {
    fetchTimekeepers('pending', params)
    fetchCounts()
  }, [])

  const columns = useMemo(() => {
    return [
      ...(selectedTab === 'all'
        ? [
            {
              columnKey: 'id',
              content: 'ID',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            },
            {
              columnKey: 'timekeeper',
              content: 'Timekeeper',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' },
              render: (cell: Cell) => {
                return cell.content === '----' ? (
                  '---'
                ) : (
                  <a className={s.link}>{cell.content as string}</a>
                )
              }
            }
          ]
        : []),
      {
        columnKey: 'vendor_name',
        content: 'Vendor',
        isSortable: true,
        isFilterable: true,
        style: { maxWidth: '400px', position: 'relative' },
        render: (cell: Cell) => {
          return typeof cell.content === 'string' && !!cell.content ? (
            <a className={s.link}>{cell.content as string}</a>
          ) : (
            '---'
          )
        }
      },
      ...(selectedTab === 'all'
        ? [
            {
              columnKey: 'classification',
              content: 'Classification',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            },
            {
              columnKey: 'requested_rate',
              content: 'Billed Rate',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            },
            {
              columnKey: 'rack_rate',
              content: 'Rack Rate',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' },
              render: (cell: Cell) => {
                return typeof cell.content === 'string' && !!cell.content ? cell.content : '---'
              }
            },
            {
              columnKey: 'currency',
              content: 'Rate Currency',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            },
            {
              columnKey: 'status',
              content: 'Status',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' },
              render: (cell: Cell) =>
                cell.content === 'approve' ? (
                  <div className={s.status}>
                    <GoCheckCircleFill color="#40A674" />
                    <span>Approved</span>
                  </div>
                ) : cell.content === 'reject' ? (
                  <div className={s.status}>
                    <GoXCircle color="#D3455B" />
                    <span>Rejected</span>
                  </div>
                ) : (
                  '---'
                )
            },
            {
              columnKey: 'effective',
              content: 'Effective Date',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' },
              render: (cell: Cell) =>
                typeof cell.content === 'string' && !!cell.content
                  ? formatDate(cell.content as string)
                  : '---'
            },
            {
              columnKey: 'rejection_reason',
              content: 'Rejection Reason',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            },
            {
              columnKey: 'reason_for_increase',
              content: 'Request Reason',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            }
          ]
        : []),
      ...(selectedTab !== 'all'
        ? [
            {
              columnKey: 'pending_count',
              content: 'Timekeepers Rates',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            },
            {
              columnKey: 'full_name',
              content: 'Submitted By',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px', position: 'relative' }
            },
            {
              columnKey: 'created_date',
              content: 'Received',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px' },
              render: (cell: Cell) =>
                typeof cell.content === 'string' && !!cell.content
                  ? formatDate(cell.content as string)
                  : '---'
            }
          ]
        : []),
      ...(selectedTab === 'completed'
        ? [
            {
              columnKey: 'completed_date',
              content: 'Completed',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px' },
              render: (cell: Cell) =>
                typeof cell.content === 'string' && !!cell.content
                  ? formatDate(cell.content as string)
                  : '---'
            },
            {
              columnKey: 'approver_list',
              content: 'Reviewed',
              isSortable: true,
              isFilterable: true,
              style: { maxWidth: '400px' },
              render: (cell: Cell) =>
                Array.isArray(cell.content) && cell.content.length ? (
                  <AvatarList
                    size={cell.content.length > 1 ? 'sm' : 'md'}
                    limit={2}
                    avatarStyles={{ marginLeft: 0 }}
                    entries={cell.content}
                  />
                ) : (
                  '---'
                )
            }
          ]
        : [])
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, view, selectedTab])

  useEffect(() => {
    setLocalState(state => ({
      ...state,
      params: {
        ...state.params,
        page: 1,
        category: selectedTab,
        ordering:
          selectedTab === 'all'
            ? { columnKey: 'effective', isDesc: true }
            : { columnKey: 'created_date', isDesc: true }
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, view])

  const filteredTks = useMemo(
    (): Array<{
      id: number
      cells: Array<{ columnKey: string; content: ReactNode }>
    }> =>
      //@ts-expect-error    TODO: Fix this
      timekeepers.map(tk => {
        return {
          id: tk.id || tk.timekeeper_file_id,
          cells: [
            ...(selectedTab === 'all'
              ? [
                  {
                    columnKey: 'id',
                    content: tk.tk_id
                  },
                  {
                    columnKey: 'timekeeper',
                    content: tk.timekeeper
                  }
                ]
              : []),
            {
              columnKey: 'vendor_name',
              content: tk.vendor_name
            },
            ...(selectedTab === 'all'
              ? [
                  {
                    columnKey: 'classification',
                    content: tk.classification
                  },
                  {
                    columnKey: 'requested_rate',
                    content: tk.requested_rate
                  },
                  {
                    columnKey: 'rack_rate',
                    content: tk.rack_rate
                  },
                  {
                    columnKey: 'currency',
                    content: tk.currency
                  },
                  {
                    columnKey: 'status',
                    content: tk.status
                  },
                  {
                    columnKey: 'effective',
                    content: tk.effective
                  },
                  {
                    columnKey: 'rejection_reason',
                    content: tk.rejection_reason ? <ChatHover text={tk.rejection_reason} /> : '---'
                  },
                  {
                    columnKey: 'reason_for_increase',
                    content: tk.reason_for_increase ? (
                      <ChatHover text={tk.rejection_reason as string} />
                    ) : (
                      '---'
                    )
                  }
                ]
              : []),
            ...(selectedTab !== 'all'
              ? [
                  {
                    columnKey: 'pending_count',
                    content:
                      selectedTab === 'pending'
                        ? `${tk.pending_count} out of ${tk.tk_count} pending`
                        : `${tk.approved_count} approved${
                            tk.rejected_count ? `, ${tk.rejected_count} rejected` : ''
                          }`
                  },
                  {
                    columnKey: 'full_name',
                    content: (
                      <div>
                        <p>{tk.full_name as string}</p>
                        <p className={s.email}>{tk.email}</p>
                      </div>
                    )
                  }
                ]
              : []),
            ...(selectedTab !== 'all'
              ? [
                  {
                    columnKey: 'created_date',
                    content: tk.created_date
                  }
                ]
              : []),
            ...(selectedTab === 'completed'
              ? [
                  {
                    columnKey: 'completed_date',
                    content: tk.completed_date
                  },
                  {
                    columnKey: 'approver_list',
                    content: tk.approver_list
                  }
                ]
              : [])
          ]
        }
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timekeepers, selectedTab]
  )

  const onChangeTabCb = (selectedTab: string) => {
    setOldSelectedTab(selectedTab)
    fetchTimekeepers(selectedTab, params)

    if (selectedTab === oldSelectedTab) return
  }

  const changeTab = (tab: string) => {
    setSelectedTab(tab)
    onChangeTabCb(tab)
  }

  const updateTable = async (params: Params) => {
    setLocalState({
      ...localState,
      params
    })

    fetchTimekeepers(selectedTab, params)
  }

  const renderCustomAction = (row: Timekeeper) => (
    <ActionsPopover timekeeper={row} copyTk={() => {}} downloadTk={() => {}} />
  )

  return (
    <section className={s.container}>
      <section className={s.panel}>
        <a href="/v2/administration/">
          <IoIosArrowBack />
          {' Back to Administration'}
        </a>

        <div className={s.panelContainer}>
          <div className={s.innerPanel}>
            <div className={s.header}>
              <h2 className={s.title} data-testid="title">
                Timekeepers
              </h2>
              <div className={s.tabs}>
                <span
                  className={cn({ [s.selected]: view === 'rates' })}
                  onClick={() => {
                    setView('rates')
                  }}
                >
                  <FaMoneyBill /> Rate Review
                </span>
                <span
                  className={cn({ [s.selected]: view === 'list' })}
                  onClick={() => {
                    setView('list')
                  }}
                >
                  <BsFillPersonCheckFill /> All Timekeepers
                </span>
              </div>
              <span className={s.rightActions}>
                <Button
                  style={{ padding: '10px 15px' }}
                  key="download"
                  onClick={() => {}}
                  isPrimary
                  isOutline
                  hasNewDesign
                >
                  Download
                </Button>
              </span>
            </div>
            <Tabs
              selectedTab={selectedTab}
              setSelectedTab={changeTab}
              pendingCount={pendingCount}
              completedCount={completedCount}
              allCount={allCount}
            />
            <DataTableWrapper
              alwaysShowLoadingSkeleton
              hasActions
              alwaysShowActions
              isLoading={isLoading}
              remotePagination
              params={params}
              categories={[]}
              rows={filteredTks}
              totalEntries={totalEntries}
              columns={columns}
              customAction={renderCustomAction}
              updateTable={updateTable}
              entryLanguage={{
                singular: 'Batch',
                plural: 'Batches'
              }}
              hasTooltip
            />
          </div>
        </div>
      </section>
    </section>
  )
}

export default EnhancedTimekeepersContainer
