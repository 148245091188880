import { APITimekeeper, Timekeeper } from './types'

export const serializeTimekeepers = (timekeepers: APITimekeeper[]): Timekeeper[] =>
  //@ts-expect-error    TODO: Fix this
  timekeepers.map(timekeeper => {
    return {
      ...timekeeper,
      ...(timekeeper.hasOwnProperty('approver_list') && Array.isArray(timekeeper.approver_list)
        ? {
            approver_list: timekeeper.approver_list
              .filter(approver => approver !== ' ')
              .map((approver, idx) => ({
                label: approver,
                id: idx
              }))
          }
        : {}),
      ...(timekeeper.hasOwnProperty('timekeeper')
        ? {
            tk_id: timekeeper.timekeeper.timekeeper_id,
            timekeeper: `${timekeeper.timekeeper.first_name} ${timekeeper.timekeeper.last_name}`,
            classification: timekeeper.timekeeper.classification,
            vendor_name: timekeeper.timekeeper.vendor_name
          }
        : {})
    }
  })
